import { IUserAttendance } from "./user-attendance.model";
import { IAttendancePolicy } from "@app/setup/model/attendance-settings.model";

type AttendanceLimitKey = 'breakReminder' | 'maxHoursPerDay';

export async function checkLimits(userProfile: string, attendanceEntry: IUserAttendance, attendanceOfTheDay: Array<IUserAttendance>, attendancePolicy: IAttendancePolicy): Promise<AttendanceLimit | undefined> {
  if (['admin', 'hr-admin'].includes(userProfile)) {
    return undefined;
  }

  const maxHoursPerDayLimit = new MaxHoursPerDayAttendanceLimit(attendanceEntry, attendancePolicy);
  const maxHoursPerDayStatus = maxHoursPerDayLimit.checkLimit(attendanceOfTheDay);

  if (maxHoursPerDayStatus?.broken) {
    return maxHoursPerDayLimit;
  }

  return undefined;
}

export class AttendanceLimit {
  public key: AttendanceLimitKey = 'breakReminder';
  public newEndTime?: number;
  public allowsSaving: boolean = true;
  public modalTitleKey: string = '';
  public modalTextKey: string = '';
  public modalData: Record<string, any>;

  constructor(public attendanceEntry: IUserAttendance, public attendancePolicy: IAttendancePolicy) {}

  getEntryDuration(entry?: IUserAttendance) {
    const attendanceEntry = entry || this.attendanceEntry;
    if (attendanceEntry.endTime === undefined || attendanceEntry.startTime === undefined) {
      return 0;
    }

    const breakDuration = attendanceEntry.breaks ? (
      attendanceEntry.breaks.reduce((total, iBreak) => (
        total + (iBreak.end !== undefined ? iBreak.end - iBreak.start : 0)
      ), 0)
    ) : (
      attendanceEntry.breakTime ?? 0
    );

    return attendanceEntry.endTime - attendanceEntry.startTime - breakDuration;
  }
}

export class MaxHoursPerDayAttendanceLimit extends AttendanceLimit {
  key: AttendanceLimitKey = 'maxHoursPerDay';
  public modalTitleKey: string = 'maxHoursPerDayTitle';
  public modalTextKey: string = 'maxHoursPerDayText';

  checkLimit(todayAttendance: Array<IUserAttendance>) {
    const limitDailyHours = this.attendancePolicy.limitDailyHours;
    if (!limitDailyHours.isActive) {
      return undefined
    }

    const otherEntries = todayAttendance.filter(iAttendance => iAttendance._id !== this.attendanceEntry._id);

    const totalTracked = [...otherEntries, this.attendanceEntry].reduce((total, iAttendance) => (
      total + this.getEntryDuration(iAttendance)
    ), 0);

    const { limit } = limitDailyHours;
    const difference = totalTracked - limit;
    const newEndTime = this.attendanceEntry.endTime - difference;
    this.newEndTime = newEndTime;

    this.modalData = { newEndTime, maxHoursPerDay: limit };
    return { broken: difference > 0, difference }
  }
}
