import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { environment } from '@env';
import { HttpClientService } from './http-client.service';
import { PersistenceService } from './persistence.service';
import { NavigationService } from './navigation.service';
import { TranslationService } from './translation.service';
import { UserEventLoggerService } from './user-event-logger.service';
import { KioskSetupService } from '@app/setup/services/kiosk-setup.service';

export type AuthErrors = 'emailNotFound' | 'userHasNoPermission' | 'unknown';
export type AuthResponse = { email: string; language: string };

@Injectable({ providedIn: 'root' })
export class AuthService {
  adminInfo: { userId?: string; orgId?: string; email?: string } = {};
  private isAuthenticated: boolean;
  private accessToken: string;
  private refreshToken: string;
  private sessionHasBeenKilled: boolean = false;

  constructor(
    private httpClient: HttpClientService,
    private persistenceService: PersistenceService,
    private navigationService: NavigationService,
    private translationService: TranslationService,
    private userEventLogger: UserEventLoggerService,
    private kioskSetUpService: KioskSetupService
  ) {}

  async signInKiosk(
    rawEmail: string
  ): Promise<{ response?: AuthResponse; error?: AuthErrors }> {
    try {
      const email = rawEmail.trim().toLowerCase();
      const response = await this.httpClient.postWithoutAuth<AuthResponse>(
        `${environment.PEOPLE_CLOUD_APP_URL}/auth/kiosk/sign-in`,
        { email }
      );
      this.adminInfo.email = response.email;
      this.translationService.setLanguage(response.language);
      return { response };
    } catch (e) {
      if (e.status === HttpStatusCode.NotFound) {
        return { error: 'emailNotFound' };
      }
      if (e.status === HttpStatusCode.Forbidden) {
        return { error: 'userHasNoPermission' };
      }
      return { error: 'unknown' };
    }
  }

  async sendCodeAgain(): Promise<void> {
    const bodyData = { email: this.adminInfo.email };

    await this.httpClient.postWithoutAuth(
      `${environment.PEOPLE_CLOUD_APP_URL}/auth/kiosk/resend-otp-code`,
      bodyData
    );
  }

  async signIn2FA(hashValue: string) {
    try {
      const requestBody = { email: this.adminInfo.email, hashValue };
      const response = await this.httpClient.postWithCredentials<{
        accessToken: string;
        refreshToken: string;
        userId: string;
        sessionNames: string[];
      }>(
        `${environment.PEOPLE_CLOUD_APP_URL}/auth/kiosk/complete-2fa-sign-in`,
        requestBody
      );
      this.kioskSetUpService.sessionNames = response.sessionNames;
      this.persistenceService.setItem(
        'sessionNames',
        JSON.stringify(response.sessionNames)
      );

      this.isAuthenticated = true;
      this.accessToken = `Bearer ${response.accessToken}`;
      this.refreshToken = response.refreshToken;
      this.adminInfo.userId = response.userId;
      this.persistenceService.setItem('userId', response.userId);
      this.persistenceService.setItem('refresh_token', this.refreshToken);
      this.persistenceService.setItem('accessToken', response.accessToken);
      this.persistenceService.clearItem('locationId');
      this.persistenceService.clearItem('sessionId');
      this.persistenceService.clearItem('selectedEntryMethod');
      await this.userEventLogger.initialize();
      this.userEventLogger.logEvent('VALID_2FA');
      return { response };
    } catch (error) {
      if (error.error === 'Not Found') {
        return { error: 'incorrectCode' };
      }
      if (error.error === 'Unauthorized') {
        return { error: 'noTrialsLeft' };
      }
      return { error };
    }
  }

  async signInStorage() {
    const userInfo: Record<string, string> = {};
    const accessTokenInLocalStorage =
      this.persistenceService.getItem('accessToken');
    const cookieExistedButWasDeleted = accessTokenInLocalStorage !== undefined;
    if (!cookieExistedButWasDeleted) {
      return false;
    }
    userInfo.userId = this.persistenceService.getItem('userId');
    userInfo.language = this.persistenceService.getItem('language');
    userInfo.accessToken = this.persistenceService.getItem('accessToken');
    this.isAuthenticated = true;
    this.adminInfo = { userId: userInfo.userId };
    this.accessToken = `Bearer ${userInfo.accessToken}`;
    this.translationService.setLanguage(userInfo.language);
    return true;
  }

  async getIsAuthenticated() {
    if (this.isAuthenticated !== undefined) {
      return this.isAuthenticated;
    }

    try {
      const success = await this.signInStorage();
      return success;
    } catch (e) {
      return false;
    }
  }

  async killKioskSession() {
    this.sessionHasBeenKilled = true;
    this.navigationService.goToLoggedOut();

    this.adminInfo = {};
    this.isAuthenticated = false;
    this.persistenceService.clearAllData();

    await this.httpClient.post(
      `${environment.PEOPLE_CLOUD_APP_URL}/auth/kiosk/revoke`,
      {
        refreshToken: this.persistenceService.getItem('refresh_token'),
        accessType: 'KioskAccess',
      },
      this.getAuthHeader()
    );
  }

  async redirectToLogIn() {
    this.adminInfo = {};
    this.isAuthenticated = false;
    this.navigationService.goToLogIn();
  }

  getAdminEmail(): string {
    return this.adminInfo?.email;
  }

  getAuthHeader() {
    return this.accessToken;
  }

  getSessionHasBeenKilled() {
    const wasKilled = this.sessionHasBeenKilled;
    this.sessionHasBeenKilled = false;
    return wasKilled;
  }
}
