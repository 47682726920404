import { Component, Inject, NgZone, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimeOffRequest } from '@app/check-in/model/time-off-request.model';

@Component({
  selector: 'kenjo-transactional-dialog',
  templateUrl: './transactional-dialog.component.html',
  styleUrls: ['./transactional-dialog.component.scss'],
})
export class TransactionalDialogComponent {
  titleText: string = 'Title';
  subtitleText: string = 'Subtitle';
  timeOffData: {
    color: string;
    name: string;
    partOfDay: string | { from: string; to: string };
    type: string;
  }[] = [];
  constructor(
    public dialogRef: MatDialogRef<TransactionalDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.timeOffData = this.data.timeOffData;
  }

  confirmDialog(): void {
    if (this.data.deleteProtection) {
      this.closeDialog(false);
      return;
    }
    this.closeDialog(true);
  }

  closeDialog(result: boolean) {
    this.dialogRef.close(result);
  }
}
