import { environment } from '@env';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { BreakReminderService } from './break-reminder.service';
import { AuthService } from '@app/shared/services/auth.service';
import { TranslatePipe } from '@app/shared/pipes/translate.pipe';
import { ErrorService } from '@app/shared/services/error.service';
import { HttpClientService } from '@app/shared/services/http-client.service';
import { UserEventLoggerService } from '@app/shared/services/user-event-logger.service';
import {
  IAttendanceCategory,
  IAttendancePolicy,
} from '@app/setup/model/attendance-settings.model';
import {
  AttendanceLimit,
  checkLimits as checkPreSaveLimits,
} from '../model/attendance-limit.model';
import {
  IAttendanceAutomation,
  IAttendanceBreak,
  IUserAttendance,
  IUserAttendanceInfo,
} from '../model/user-attendance.model';
import {
  filterForbiddenUserAttendanceFields,
  isAutoDeductBreakNeeded,
} from '@app/shared/helpers/attendance.helpers';
import { UserAttendanceService } from './user-attendance.service';
import {
  PunchClockValidator,
  UserAttendance as IUserAttendanceLib,
} from '@carlos-orgos/kenjo-shared-libs';

type PincodeValidationResponse = {
  response?: IUserAttendanceInfo;
  error?: string;
};
@Injectable({
  providedIn: 'root',
})
export class KioskService {
  public pincodeUser$ = new BehaviorSubject<IUserAttendanceInfo | undefined>(
    undefined
  );
  public attendancePolicy$ = new BehaviorSubject<IAttendancePolicy | undefined>(
    undefined
  );
  public attendanceCategoriesInfo$ = new BehaviorSubject<
    Array<IAttendanceCategory> | undefined
  >(undefined);
  public userPinCode: string;
  public actualAttendance$ = new BehaviorSubject<IUserAttendance | undefined>(
    {}
  );

  public lastAttendanceEntry$ = new BehaviorSubject<
    IUserAttendance | undefined
  >({});
  public currentOpenBreak$ = new BehaviorSubject<IAttendanceBreak | undefined>(
    undefined
  );
  public brokenAttendanceLimit$ = new BehaviorSubject<
    AttendanceLimit | undefined
  >(undefined);
  public lastModifiedEntry: IUserAttendance;
  private OVERNIGHT_MARGIN = 14;
  private DAY_HOURS = 24;

  public showDailyTotal = false;

  public splittedAttendanceEntries: {
    modifiedEntries: IUserAttendance[];
    originalEntries: IUserAttendance[];
    result: string;
  };
  public splittedAttendanceEntries$ = new BehaviorSubject<
    IUserAttendance[] | undefined
  >(undefined);

  constructor(
    private http: HttpClientService,
    private auth: AuthService,
    private errorService: ErrorService,
    private translatePipe: TranslatePipe,
    private userEventLogger: UserEventLoggerService,
    private breakReminderService: BreakReminderService,
    private userAttendanceService: UserAttendanceService
  ) {}

  async validatePincode(pincode: string): Promise<PincodeValidationResponse> {
    try {
      const userAttendanceInfo = await this.http.get<IUserAttendanceInfo>(
        `${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/validate-pincode/${pincode}`,
        this.auth.getAuthHeader()
      );
      this.checkAttendanceEntries(userAttendanceInfo);
      this.pincodeUser$.next(userAttendanceInfo);
      this.attendanceCategoriesInfo$.next(
        userAttendanceInfo.attendanceCategories
      );
      this.userPinCode = pincode;
      this.computeLastAttendanceEntry(userAttendanceInfo.userAttendance);
      return { response: userAttendanceInfo };
    } catch (error) {
      if (error.status === 422 || error.status === 401) {
        return { error: 'incorrectCode' };
      }
      this.errorService.logErrorForDevelopers(error);
      return { error };
    }
  }

  async refreshAttendanceInfo() {
    try {
      const currentUserId = this.pincodeUser$.getValue().userId;
      const userAttendance = await this.http.get<Array<IUserAttendance>>(
        `${environment.PEOPLE_CLOUD_APP_URL}/controller/kiosk/attendance-info/${currentUserId}`,
        this.auth.getAuthHeader()
      );
      this.actualAttendance$.next(userAttendance[userAttendance.length - 1]);
      this.pincodeUser$.next({
        ...this.pincodeUser$.getValue(),
        userAttendance,
      });
      this.computeLastAttendanceEntry(userAttendance);
      return { response: userAttendance };
    } catch (error) {
      this.errorService.logErrorForDevelopers(error);
      return { error: 'NOT FOUND' };
    }
  }

  async clockIn(): Promise<{ error: boolean }> {
    const newAttendanceEntry = this.createEmptyAttendanceEntry();
    newAttendanceEntry.startTime =
      new Date().getHours() * 60 + new Date().getMinutes();
    newAttendanceEntry['pinCode'] = this.userPinCode;
    const overlappingShift = this.checkOverlapping(
      newAttendanceEntry,
      this.pincodeUser$.getValue().userAttendance
    );
    if (overlappingShift) {
      this.errorService.displayError(
        this.translatePipe.transform('overlappingEntry')
      );
      return { error: true };
    }

    try {
      await this.http.post(
        `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/`,
        newAttendanceEntry,
        this.auth.getAuthHeader()
      );
      await this.userEventLogger.logEvent('CHECK_IN_PIN');
      await this.refreshAttendanceInfo();
      return { error: false };
    } catch (error) {
      if (error.status === 0) {
        this.errorService.connectionErrorHandler();
        return { error };
      } else if (
        error.error ===
        'Attendance entry can not be within a time time off request.'
      ) {
        this.errorService.displayError(
          this.translatePipe.transform('timeOffEntryError')
        );
        return { error };
      }
      this.errorService.logAndDisplayUnrecognizedError(error);
      return { error };
    }
  }

  async checkOut(params?: { fromBreak: boolean }): Promise<boolean> {
    const currentAttendanceEntry = this.lastAttendanceEntry$.getValue();
    const attendancePolicy =
      this.attendancePolicy$.getValue() ??
      this.pincodeUser$.getValue()?.userPolicy;
    const minutes = new Date().getHours() * 60 + new Date().getMinutes();
    currentAttendanceEntry.endTime =
      minutes < currentAttendanceEntry.startTime ? minutes + 1440 : minutes;
    let userAttendance = [...this.pincodeUser$.getValue().userAttendance];
    userAttendance = userAttendance.filter(
      (iUserAttendance) =>
        iUserAttendance.startTime !== currentAttendanceEntry.startTime &&
        iUserAttendance.endTime !== currentAttendanceEntry.endTime
    );

    const isOverlapping = this.checkOverlapping(
      currentAttendanceEntry,
      userAttendance
    );
    let [_, todayAttendance] = this.splitAttendanceEntries(
      this.pincodeUser$.getValue().userAttendance
    );
    const preSaveBrokenLimit = await checkPreSaveLimits(
      this.pincodeUser$.getValue().userProfile,
      currentAttendanceEntry,
      todayAttendance,
      this.pincodeUser$.getValue()?.userPolicy
    );

    if (preSaveBrokenLimit?.allowsSaving === false) {
      return;
    }

    let endTime =
      preSaveBrokenLimit?.newEndTime !== undefined &&
      !attendancePolicy?.limitDailyHours.conflicts
        ? preSaveBrokenLimit?.newEndTime
        : currentAttendanceEntry.endTime;

    try {
      const requestBody = {
        endTime,
        pinCode: this.userPinCode,
        interface: 'kiosk-pin-code',
      } as IUserAttendance;
      if (preSaveBrokenLimit?.key === 'maxHoursPerDay') {
        await this.userEventLogger.logEvent('MAX_DAILY_HOURS');
        requestBody.automation =
          params?.fromBreak === true
            ? this.getMaxHoursPerDayFromBreakAutomationInfo(
                currentAttendanceEntry,
                preSaveBrokenLimit.newEndTime
              )
            : this.getMaxHoursPerDayAutomationInfo(
                currentAttendanceEntry,
                preSaveBrokenLimit.newEndTime
              );
      }

      if (isOverlapping) {
        const body = {
          entryToBeSplit: {
            ...currentAttendanceEntry,
            interface: 'kiosk-pin-code',
            pinCode: this.userPinCode,
            profileKey: this.pincodeUser$.getValue().userProfile,
          },
        };

        const { modifiedEntries, originalEntries, result } =
          await this.http.post<{
            result: string;
            modifiedEntries: IUserAttendance[];
            originalEntries: IUserAttendance[];
          }>(
            `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/split-entries`,
            body,
            this.auth.getAuthHeader()
          );
        this.splittedAttendanceEntries = {
          modifiedEntries,
          originalEntries,
          result,
        };
        this.splittedAttendanceEntries$.next(
          this.splittedAttendanceEntries.modifiedEntries
        );
        endTime = modifiedEntries[modifiedEntries.length - 1].endTime;
        this.lastAttendanceEntry$.next(
          modifiedEntries[modifiedEntries.length - 1]
        );
        this.lastModifiedEntry = modifiedEntries[modifiedEntries.length - 1];
      } else {
        await this.http.put(
          `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/${currentAttendanceEntry._id}`,
          {
            ...requestBody,
            profileKey: this.pincodeUser$.getValue().userProfile,
          },
          this.auth.getAuthHeader()
        );
      }

      await this.breakReminderService.checkBreakReminder(
        currentAttendanceEntry,
        todayAttendance
      );
      if (
        !this.splittedAttendanceEntries ||
        this.splittedAttendanceEntries.result !== 'errors'
      ) {
        this.brokenAttendanceLimit$.next(preSaveBrokenLimit);
      }

      if (!this.splittedAttendanceEntries$.getValue()) {
        this.lastModifiedEntry = { ...currentAttendanceEntry, endTime };
      }
      if (
        !this.breakReminderService?.breakReminderInfo$?.getValue()?.openReminder
      ) {
        await this.handleAutoDeductBreak(
          currentAttendanceEntry,
          attendancePolicy,
          {
            ...requestBody,
            endTime,
          },
          this.pincodeUser$.getValue().userProfile
        );
      }
      await this.refreshAttendanceInfo();

      return true;
    } catch (error) {
      if (error.status === 0) {
        this.errorService.connectionErrorHandler();
        return false;
      }
      this.errorService.logAndDisplayUnrecognizedError(error);
      return false;
    }
  }

  checkAttendanceLimits(
    attendanceEntry: IUserAttendance
  ): AttendanceLimit | undefined {
    const otherAttendanceEntries = this.pincodeUser$.getValue().userAttendance;
    // otherAttendanceEntries.pop();
    const overlappingShift = this.checkOverlapping(
      attendanceEntry,
      otherAttendanceEntries
    );
    if (overlappingShift) {
      // TO-DO: Open split request dialog
      // this.errorService.displayError(this.translatePipe.transform('overlappingEntry'));
      // this.brokenAttendanceLimit$.next(new AttendanceLimit('overlap'));
    }

    return undefined;
  }

  getMaxHoursPerDayAutomationInfo(
    attendanceEntry: IUserAttendance,
    newEndTime: number
  ): IAttendanceAutomation {
    return {
      key: 'attendance-automation-max-hours',
      changes: [
        {
          fieldChanged: 'endTime',
          newValue: newEndTime,
          oldValue: attendanceEntry.endTime,
        },
      ],
    };
  }

  getMaxHoursPerDayFromBreakAutomationInfo(
    attendanceEntry: IUserAttendance,
    newEndTime: number
  ): IAttendanceAutomation {
    return {
      key: 'attendance-automation-max-hours',
      changes: [
        {
          fieldChanged: 'breaks',
          newValue: '',
          oldValue: `${attendanceEntry.endTime}-`,
        },
        {
          fieldChanged: 'endTime',
          newValue: newEndTime,
          oldValue: undefined,
        },
      ],
    };
  }

  clearAttendanceLimitModal() {
    this.brokenAttendanceLimit$.next(undefined);
  }

  async startBreak() {
    const currentAttendanceEntry = this.lastAttendanceEntry$.getValue();
    const startBreakTime = this.getBreakTimeFor(currentAttendanceEntry);

    const [_, todayAttendance] = this.splitAttendanceEntries(
      this.pincodeUser$.getValue().userAttendance
    );
    const brokenLimitIfItWasCheckOut = await checkPreSaveLimits(
      this.pincodeUser$.getValue().userProfile,
      { ...currentAttendanceEntry, endTime: startBreakTime },
      todayAttendance,
      this.pincodeUser$.getValue()?.userPolicy
    );

    if (brokenLimitIfItWasCheckOut?.key === 'maxHoursPerDay') {
      return { error: brokenLimitIfItWasCheckOut?.key };
    }

    const breaks = currentAttendanceEntry.breaks
      ? [...currentAttendanceEntry.breaks, { start: startBreakTime }]
      : [{ start: startBreakTime }];
    const requestBody = {
      breaks,
      pinCode: this.userPinCode,
      interface: 'kiosk-pin-code',
    };

    const tempUserAttendance = { ...currentAttendanceEntry };
    const minutes = new Date().getHours() * 60 + new Date().getMinutes();
    tempUserAttendance.endTime =
      minutes < tempUserAttendance.startTime ? minutes + 1440 : minutes;
    let userAttendance = [...this.pincodeUser$.getValue().userAttendance];
    userAttendance = userAttendance.filter(
      (iUserAttendance) =>
        iUserAttendance.startTime !== tempUserAttendance.startTime &&
        iUserAttendance.endTime !== tempUserAttendance.endTime &&
        iUserAttendance.date === tempUserAttendance.date
    );

    const isOverlapping = this.checkOverlapping(
      tempUserAttendance,
      userAttendance
    );

    if (isOverlapping) {
      const body = {
        entryToBeSplit: {
          ...tempUserAttendance,
          interface: 'kiosk-pin-code',
          pinCode: this.userPinCode,
          profileKey: this.pincodeUser$.getValue().userProfile,
        },
        breakStart: tempUserAttendance.endTime,
      };

      const { modifiedEntries, originalEntries, result } =
        await this.http.post<{
          result: string;
          modifiedEntries: IUserAttendance[];
          originalEntries: IUserAttendance[];
        }>(
          `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/split-entries`,
          body,
          this.auth.getAuthHeader()
        );
      this.splittedAttendanceEntries = {
        modifiedEntries,
        originalEntries,
        result,
      };
      this.splittedAttendanceEntries$.next(
        this.splittedAttendanceEntries.modifiedEntries
      );
      this.lastAttendanceEntry$.next(
        modifiedEntries[modifiedEntries.length - 1]
      );
      this.lastModifiedEntry = modifiedEntries[modifiedEntries.length - 1];
      return { error: false };
    }

    try {
      await this.http.put(
        `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/${currentAttendanceEntry._id}`,
        requestBody,
        this.auth.getAuthHeader()
      );
      await this.userEventLogger.logEvent('KIOSK_BREAK');
      await this.refreshAttendanceInfo();
      return { error: false };
    } catch (error) {
      if (error.status === 0) {
        this.errorService.connectionErrorHandler();
        return { error };
      }
      this.errorService.logAndDisplayUnrecognizedError(error);
      return { error };
    }
  }

  async finishBreak() {
    const currentAttendanceEntry = this.lastAttendanceEntry$.getValue();
    const currentOpenBreak = this.currentOpenBreak$.getValue();
    const currentBreak = this.currentOpenBreak$.getValue();
    if (!currentBreak) {
      return;
    }

    const updatedBreaks = [...currentAttendanceEntry.breaks];
    const breakToFinish = updatedBreaks.find(
      (iBreak) => iBreak.start === currentOpenBreak.start
    );
    breakToFinish.end = this.getBreakTimeFor(currentAttendanceEntry);
    const requestBody = {
      breaks: updatedBreaks,
      pinCode: this.userPinCode,
      interface: 'kiosk-pin-code',
    };
    try {
      await this.http.put(
        `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/${currentAttendanceEntry._id}`,
        requestBody,
        this.auth.getAuthHeader()
      );
      await this.refreshAttendanceInfo();
      return { error: false };
    } catch (error) {
      if (error.status === 0) {
        this.errorService.connectionErrorHandler();
        return { error };
      }
      this.errorService.logAndDisplayUnrecognizedError(error);
      return { error };
    }
  }

  async insertBreakOfDuration(breakDuration: number): Promise<boolean> {
    try {
      const currentAttendanceEntry = this.lastAttendanceEntry$.getValue();
      const breakEnd = currentAttendanceEntry.endTime;
      const newBreak: IAttendanceBreak = {
        end: breakEnd,
        start: breakEnd - breakDuration,
      };
      const requestBody = {
        breaks: [newBreak],
        pinCode: this.userPinCode,
        interface: 'kiosk-pin-code',
      };
      await this.http.put(
        `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/${currentAttendanceEntry._id}`,
        requestBody,
        this.auth.getAuthHeader()
      );
      this.brokenAttendanceLimit$.next(undefined);
      this.lastModifiedEntry = {
        ...currentAttendanceEntry,
        breaks: [newBreak],
        breakTime: breakDuration,
      };
      await this.refreshAttendanceInfo();
      return true;
    } catch (error) {
      if (error.status === 0) {
        this.errorService.connectionErrorHandler();
      }
      return false;
    }
  }

  async updateAttendanceCategory(category: IAttendanceCategory | null) {
    try {
      const payload = {
        attendanceCategoryId: null,
        attendanceSubCategoryId: null,
      };
      if (category !== null) {
        if (category.parentCategoryId !== undefined) {
          payload.attendanceCategoryId = category.parentCategoryId;
          payload.attendanceSubCategoryId = category._id;
        } else {
          payload.attendanceCategoryId = category._id;
          payload.attendanceSubCategoryId = null;
        }
      }
      const currentAttendanceEntry = this.lastAttendanceEntry$.getValue();
      const result = await this.http.put(
        `${environment.PEOPLE_CLOUD_APP_URL}/user-attendance-db/${currentAttendanceEntry._id}`,
        payload,
        this.auth.getAuthHeader()
      );
      await this.refreshAttendanceInfo();
      return { result };
    } catch (error) {
      this.errorService.logAndDisplayUnrecognizedError(error);
      if (error.status === 0) {
        this.errorService.connectionErrorHandler();
      }
      return { error };
    }
  }

  async getDailyTime(): Promise<{ totalWorkTime: number }> {
    if (this.showDailyTotal) {
      try {
        const userId = this.pincodeUser$.getValue().userId;
        const date = new Date();
        const currentMinutes = date.getMinutes() + date.getHours() * 60;

        const result = await this.http.post<{ totalWorkTime: number }>(
          `${environment.PEOPLE_CLOUD_APP_URL}/controller/attendance/punch-clock/daily-time`,
          {
            date: new Date(date).setUTCHours(0, 0, 0, 0),
            currentMinutes,
            _id: userId,
          },
          this.auth.getAuthHeader()
        );
        return result;
      } catch (error) {
        this.errorService.logErrorForDevelopers(error);
      }
    }
    return { totalWorkTime: 0 };
  }

  private checkAttendanceEntries(
    userAttendanceInfo: IUserAttendanceInfo
  ): void {
    const validatedAttendanceEntries = userAttendanceInfo.userAttendance.filter(
      (attendanceEntries) =>
        !attendanceEntries._deleted &&
        new Date(attendanceEntries.date).toISOString().split('T')[0] ===
          new Date().toISOString().split('T')[0]
    );
    this.showDailyTotal = validatedAttendanceEntries.length > 1;
  }

  computeLastAttendanceEntry(
    yesterdayAndTodayAttendance: Array<IUserAttendance>
  ) {
    if (!yesterdayAndTodayAttendance?.length) {
      this.lastAttendanceEntry$.next(undefined);
      return;
    }

    const [yesterdayAttendance, todayAttendance] = this.splitAttendanceEntries(
      yesterdayAndTodayAttendance
    );

    if (this.lastModifiedEntry) {
      this.lastAttendanceEntry$.next(this.lastModifiedEntry);
      this.lastModifiedEntry = null;
      return;
    }

    const isTimeOff = todayAttendance.every(
      (attendance) => attendance._allowanceType !== undefined
    );
    if (todayAttendance.length === 0 || isTimeOff) {
      const yesterdayEntry =
        this.getYesterdayOpenAttendanceEntry(yesterdayAttendance);
      const openBreak = yesterdayEntry?.breaks?.find(
        (iBreak) => iBreak.start !== undefined && iBreak.end === undefined
      );
      this.lastAttendanceEntry$.next(yesterdayEntry);
      this.currentOpenBreak$.next(openBreak);
      return;
    }

    const sorted = todayAttendance.sort((a, b) => {
      if (a.startTime !== b.startTime) {
        if (a.endTime === undefined && b.endTime !== undefined) {
          return 1;
        } else if (b.endTime === undefined && a.endTime !== undefined) {
          return -1;
        }
        return (a.startTime ?? 0) > (b.startTime ?? 0) ? 1 : -1;
      } else {
        return (a.endTime ?? 0) > (b.endTime ?? 0) ? -1 : 1;
      }
    });
    const lastAttendanceEntry = sorted[sorted.length - 1];
    const openBreak = lastAttendanceEntry?.breaks?.find(
      (iBreak) => iBreak.start !== undefined && iBreak.end === undefined
    );
    this.lastAttendanceEntry$.next(lastAttendanceEntry);
    this.currentOpenBreak$.next(openBreak);
  }

  createEmptyAttendanceEntry() {
    return {
      _userId: this.pincodeUser$.getValue().userId,
      ownerId: this.pincodeUser$.getValue().userId,
      date: this.getTodayAsIfItWasUTC(),
      startTime: undefined,
      endTime: undefined,
      startBreakTime: undefined,
      breakTime: undefined,
      comment: undefined,
      breaks: [],
      interface: 'kiosk-pin-code',
    };
  }

  getTodayAsIfItWasUTC() {
    const today = new Date();
    const todayDate = today.getDate();
    const todayMonth = today.getMonth() + 1;
    const todayYear = today.getFullYear();
    const isoString = `${todayYear}-${
      todayMonth < 10 ? '0' + todayMonth : todayMonth
    }-${todayDate < 10 ? '0' + todayDate : todayDate}T00:00:00.000Z`;
    return new Date(isoString);
  }

  checkOverlapping(
    entry: IUserAttendance,
    otherEntries: Array<IUserAttendance>
  ) {
    if (entry.startTime === undefined) {
      return false;
    }

    const isOverlappingWithTimeOf = PunchClockValidator.checkCheckOut({
      currentAttendanceEntry: entry as IUserAttendanceLib,
      timeOffRequests: this.pincodeUser$.getValue().timeOffRequest,
    });
    if (isOverlappingWithTimeOf !== true) {
      return true;
    }
    return otherEntries.some((iEntry) => {
      if (
        iEntry.startTime === undefined ||
        iEntry.endTime === undefined ||
        new Date(iEntry.date).getDate() !== new Date().getDate()
      ) {
        return false;
      }
      const startIsInsideOtherEntry =
        entry.startTime < iEntry.endTime && entry.startTime >= iEntry.startTime;
      if (entry.endTime === undefined) {
        return startIsInsideOtherEntry;
      }
      const endIsInsideOtherEntry =
        entry.endTime !== undefined &&
        entry.endTime >= iEntry.startTime &&
        entry.endTime <= iEntry.endTime;
      const otherEntryIsInside =
        entry.endTime !== undefined &&
        entry.startTime <= iEntry.startTime &&
        entry.endTime >= iEntry.endTime;
      return (
        startIsInsideOtherEntry || endIsInsideOtherEntry || otherEntryIsInside
      );
    });
  }

  flushPincodeUser() {
    this.pincodeUser$.next(undefined);
    this.userPinCode = undefined;
  }

  getYesterdayOpenAttendanceEntry(yesterdayAttendance: Array<IUserAttendance>) {
    const result = yesterdayAttendance.find((iAttendance) => {
      const isOpen =
        iAttendance.endTime === undefined &&
        iAttendance.startTime !== undefined;
      if (!isOpen) {
        return false;
      }
      const yesterdayShiftStartHour = iAttendance.startTime / 60;
      const todayCurrentHour = new Date().getHours();
      const differenceInHours =
        this.DAY_HOURS + todayCurrentHour - yesterdayShiftStartHour;
      return differenceInHours <= this.OVERNIGHT_MARGIN;
    });
    return result;
  }

  getLastYesterdayEntry(yesterdayAttendance: Array<IUserAttendance>) {
    const lastAttendanceEntry = yesterdayAttendance.reduce(
      (lastEntry, iAttendance) => {
        return lastEntry.startTime < iAttendance.startTime
          ? iAttendance
          : lastEntry;
      },
      yesterdayAttendance[0]
    );
    return lastAttendanceEntry;
  }

  splitAttendanceEntries(
    attendanceEntries: Array<IUserAttendance>
  ): [Array<IUserAttendance>, Array<IUserAttendance>] {
    return attendanceEntries.reduce(
      ([yesterday, today]: any, iAttendance) => {
        const isToday =
          new Date(iAttendance.date).toUTCString() ===
          this.getTodayAsIfItWasUTC().toUTCString();
        if (isToday) {
          return [yesterday, [...today, iAttendance]];
        }
        return [[...yesterday, iAttendance], today];
      },
      [new Array<IUserAttendance>(), new Array<IUserAttendance>()]
    );
  }

  getEntryDuration(entry: IUserAttendance) {
    if (entry.endTime === undefined || entry.startTime === undefined) {
      return 0;
    }

    const breakDuration = entry.breaks
      ? entry.breaks.reduce(
          (total, iBreak) =>
            total + (iBreak.end !== undefined ? iBreak.end - iBreak.start : 0),
          0
        )
      : entry.breakTime ?? 0;

    return entry.endTime - entry.startTime - breakDuration;
  }

  getBreakDuration(entry: IUserAttendance) {
    const currentMinutes = new Date().getHours() * 60 + new Date().getMinutes();
    const isOvernight = this.getIsOvernight(entry);
    const onGoingBreak = entry.breaks.find((iBreak) => !iBreak.end);
    const duration =
      (isOvernight ? currentMinutes + 1440 : currentMinutes) -
      onGoingBreak.start;
    return duration;
  }

  getBreakTimeFor(entry: IUserAttendance) {
    let numberOfMinutes = new Date().getHours() * 60 + new Date().getMinutes();
    if (this.getIsOvernight(entry)) {
      numberOfMinutes += 1440;
    }
    return numberOfMinutes;
  }

  getIsOvernight(entry: IUserAttendance): boolean {
    const today = new Date();
    const entryDate = new Date(entry.date);
    const isPreviousDay = entryDate.getDate() < today.getDate();
    const isPreviousMonth = entryDate.getMonth() < today.getMonth();
    const isPreviousYear = entryDate.getFullYear() < entryDate.getFullYear();

    return isPreviousDay || isPreviousMonth || isPreviousYear;
  }

  async handleAutoDeductBreak(
    currentEntry: IUserAttendance,
    attendancePolicy: IAttendancePolicy,
    updateBody: IUserAttendance,
    profileKey: string
  ) {
    const userProfile = this.pincodeUser$.getValue().userProfile;

    const isBreakNeeded = (entry) =>
      isAutoDeductBreakNeeded(entry, attendancePolicy, userProfile) &&
      entry?._id;
    try {
      if (!this.splittedAttendanceEntries$.getValue()?.length) {
        const entryToUpdate = { ...currentEntry, endTime: updateBody.endTime };
        if (isBreakNeeded(entryToUpdate)) {
          const updateBodyFiltered =
            filterForbiddenUserAttendanceFields(updateBody);
          await this.userAttendanceService.updateAttendanceEntry(
            currentEntry,
            updateBodyFiltered,
            profileKey
          );
        }
      } else {
        const promises = this.splittedAttendanceEntries$
          ?.getValue()
          .filter(isBreakNeeded)
          .map((entry) => {
            this.userAttendanceService.updateAttendanceEntry(
              entry,
              { endTime: entry?.endTime },
              profileKey
            );
          });
        await Promise.all(promises);
      }
    } catch {
      // Do nothing
    } finally {
      await this.refreshAttendanceInfo();
    }
  }
}
