import { Injectable } from '@angular/core';

const persistenceKeys = [
  'language',
  'sessionId',
  'locationId',
  'refresh_token',
  'sessionKilled',
  'selectedEntryMethod',
  'welcomeMessage',
  'pinCodeActive',
  'qrCodeActive',
  'selectedThemeColor',
  'version',
  'accessToken',
  'userId',
] as const;
type PersistenceKey = (typeof persistenceKeys)[number];

@Injectable({ providedIn: 'root' })
export class PersistenceService_Cookie {
  getItem(key: PersistenceKey) {
    let value: string;
    document.cookie.split(';').some((iCookieStr) => {
      const [cookieName, cookieValue] = iCookieStr.split('=');
      if (cookieName.trim() === key) {
        value = cookieValue;
        return true;
      }
    });
    return value;
  }

  setItem(key: PersistenceKey, value: string) {
    const tenYearsFromNow = 10 * 365 * 24 * 60 * 60 * 1000;
    const expiration = new Date(Date.now() + tenYearsFromNow);
    document.cookie = `${key}=${value}; Expires=${expiration.toUTCString()}`;
  }

  clearItem(key: PersistenceKey) {
    document.cookie = `${key}=; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }

  clearAllData() {
    const cookies = document.cookie.split(';');

    cookies.forEach((iCookie) => {
      const equalIndex = iCookie.indexOf('=');
      const key = equalIndex > -1 ? iCookie.substring(0, equalIndex) : iCookie;
      this.clearItem(key.trim() as PersistenceKey);
    });
  }
}

@Injectable({ providedIn: 'root' })
export class PersistenceService {
  getItem(key: PersistenceKey) {
    return localStorage.getItem(key) ?? undefined;
  }
  setItem(key: PersistenceKey, value: string) {
    localStorage.setItem(key, value);
  }
  clearItem(key: PersistenceKey) {
    localStorage.removeItem(key);
  }
  clearAllData() {
    localStorage.clear();
  }
}
