import { Injectable } from '@angular/core';
import { KioskService } from './kiosk.service';
import { MatDialog } from '@angular/material/dialog';
import { NavigationService } from '@app/shared/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class KickOutTimerService {
  timeoutIntervalRef: any;
  constructor(private kioskService: KioskService, private navigationService: NavigationService, private dialogRef: MatDialog) { }

  initTimeout() {
    this.timeoutIntervalRef = setTimeout(() => { this.kickOut() }, 20 * 1000);
  }

  restartTimeout() {
    this.flushTimeout();
    this.initTimeout();
  }

  flushTimeout() {
    clearTimeout(this.timeoutIntervalRef);
  }

  kickOut() {
    this.flushTimeout();
    this.dialogRef.closeAll();
    this.kioskService.flushPincodeUser();
    this.navigationService.goToPinCode();
  }
}
