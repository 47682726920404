export const environment = {
  "production": false,
  "PEOPLE_CLOUD_APP_URL": "https://qa-api.kenjo.io",
  "ORGOS_WEB_APP_URL": "https://qa-env.kenjo.io",
  "delay": "false",
  "AMPLITUDE_IGNORE_DOMAINS": "kenjo.io,orgos.io",
  "AMPLITUDE_API_KEY": "6bebc3de7b12717ce6c66377fd9b8b91",
  "SENTRY_DSN": "https://dd740f467ec778222e0eebce46f3c873@sentry.io/4507492581244928",
  "SLUG_COMMIT_AWS": "<<SLUG_COMMIT>>",
  "APP_NAME_AWS": "kenjo-kiosk-<<ENVIRONMENT>>",
  "ENABLE_ERRORS_BY_CONSOLE": "false"
};